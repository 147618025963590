export const onInitialClientRender = () => {
  // wait to init GTM until after React has hydrated in this lifecycle
  console.log("gtm working")
  var script = document.createElement("script")
  script.innerHTML = `(function (w, d, s, l, i) {
    w[l] = w[l] || []
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : ""
    j.async = true
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
    f.parentNode.insertBefore(j, f)
  })(window, document, "script", "dataLayer", "GTM-WD2S38G")`
  document.head.prepend(script)

  var script1 = document.createElement("script")
  script1.type = "text/javascript"
  script1.src =
    "https://www.googletagmanager.com/gtag/js?id=G-6WKNR9L20S&l=dataLayer&cx=c"
  script1.async = true
  document.head.prepend(script1)

  var script2 = document.createElement("script")
  script2.type = "text/javascript"
  script2.src = "https://www.google-analytics.com/analytics.js"
  script2.async = true
  document.head.prepend(script2)

  var script3 = document.createElement("script")
  script3.innerHTML = `
  (adsbygoogle = window.adsbygoogle || []).push({
    google_ad_client: "ca-pub-3529080561800331",
    enable_page_level_ads: true,
    overlays: {bottom: true}
  });
  `
  document.head.prepend(script3)

  var script4 = document.createElement("script")
  script4.crossOrigin = "anonymous"
  script4.src =
    "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3529080561800331"
  script4.async = true
  document.head.prepend(script4)

  var noscript = document.createElement("noscript")
  var iframe = document.createElement("iframe")
  iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-WD2S38G"
  iframe.height = "0"
  iframe.width = "0"
  iframe.style = "display:none;visibility:hidden"
  noscript.appendChild(iframe)
  document.body.prepend(noscript)
}
